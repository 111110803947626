export const animationOne = {
  in: {
    x: 0
  },
  out: {
    x: -2000
  }
}



export const animationTwo = {
  in: {
    x: 0
  },
  out: {
    x: 2000
  }
}

export const transition = {
  duration: 10
}
